<script setup>
import MarkdownIt from "markdown-it";
import { computed, shallowRef } from "vue";

const props = defineProps(["message"]);
const md = shallowRef(
  new MarkdownIt({
    linkify: true,
  })
);

const defaultLinkOpenRenderer =
  md.value.renderer.rules.link_open ||
  ((tokens, idx, options, env, self) => self.renderToken(tokens, idx, options));

md.value.renderer.rules.link_open = (tokens, idx, options, env, self) => {
  const aIndex = tokens[idx].attrIndex("target");
  const classIndex = tokens[idx].attrIndex("class");
  // const hrefIndex = tokens[idx].attrIndex("href");
  // if (hrefIndex >= 0 && localStorage.getItem("mobile") !== null) {
  //   const curUrl = tokens[idx].attrs[hrefIndex][1];
  //   const updatedUrl = curUrl.replace(
  //     /^https:\/\/ziplyfiber\.com\/myaccount/,
  //     "ziplyfiber://myaccount"
  //   );
  //   tokens[idx].attrs[hrefIndex][1] = updatedUrl;
  // }
  const customClass =
    classIndex < 0
      ? "external-link"
      : `${tokens[idx].attrs[classIndex][1]} external-link`;
  if (aIndex < 0) {
    tokens[idx].attrPush(["target", "_blank"]);
  } else {
    tokens[idx].attrs[aIndex][1] = "_blank";
  }

  if (classIndex < 0) {
    tokens[idx].attrPush(["class", customClass]);
  } else {
    tokens[idx].attrs[classIndex][1] = customClass;
  }

  return defaultLinkOpenRenderer(tokens, idx, options, env, self);
};

const renderedMessage = computed(() => {
  const env = {};
  const tokens = md.value.parse(props.message, env);

  const references = env.references || {};

  const referencesList = Object.entries(references)
    .map(([key, value]) => ({
      id: key,
      href: value.href,
      title: value.title,
    }))
    .sort((a, b) => a.id.localeCompare(b.id));

  if (referencesList.length > 0) {
    // add [] to reference within the text
    for (let j = 0; j < tokens.length; j++) {
      if (tokens[j].children && tokens[j].children.length >= 3) {
        for (let i = 1; i < tokens[j].children.length - 1; i++) {
          if (
            tokens[j].children[i].type === "text" &&
            tokens[j].children[i - 1].type === "link_open" &&
            tokens[j].children[i + 1].type === "link_close"
          ) {
            const refHref = tokens[j].children[i - 1].attrGet("href");
            const ref = referencesList.find(
              (ref) =>
                ref.href === refHref && ref.id === tokens[j].children[i].content
            );
            if (ref) {
              tokens[j].children[
                i
              ].content = `[${tokens[j].children[i].content}]`;
            }
          }
        }
      }
    }
  }

  const referencesListHtml = `<ol>${referencesList
    .map(
      (ref) =>
        `<li><a href="${ref.href}" title="${ref.title}" target="_blank">${ref.title}</a></li>`
    )
    .join("")}</ol>`;

  const html = md.value.renderer.render(tokens, md.value.options, env);

  if (referencesList.length === 0) {
    return html;
  }
  return (
    html +
    `<strong class="zf-text-sm">References:</strong><br>${referencesListHtml}`
  );
});
</script>
<template>
  <div v-html="renderedMessage"></div>
</template>
