import { AES, enc } from "crypto-js";
import { getFingerprint } from "@thumbmarkjs/thumbmarkjs";

export const runSetupBeforeChat = async (
  hashKey,
  fingerPrint,
  isNewCustomer,
  isZiplyCustomer,
  foundCustomerSession,
  preChatSurveyResponses
) => {
  try {
    const customerData = await getCustomerSession({ uuidf: fingerPrint.value });
    if (customerData.success) {
      foundCustomerSession.value = true;
      try {
        const decryptedCustomerData = decrypt(
          customerData?.data?.response,
          hashKey.value ? hashKey.value : ""
        );
        if (decryptedCustomerData?.data?.type === "CST") {
          isZiplyCustomer.value = true;
          preChatSurveyResponses.value.channel_id = "Sales";
        } else {
          isZiplyCustomer.value = false;
          isNewCustomer.value = true;
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      if (localStorage.getItem("mobile")) {
        foundCustomerSession.value = null;
        isZiplyCustomer.value = null;
      }
    }
  } catch (e) {
    console.log(e);
    if (localStorage.getItem("mobile")) {
      foundCustomerSession.value = null;
      isZiplyCustomer.value = null;
    }
  }
};

export const getCustomerSession = async (data) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_CUSTOMER_SESSION_API_URL}/extract`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          ...data,
        }),
      }
    );
    const resData = await response.json();
    return resData?.success
      ? { success: true, data: resData }
      : { success: false, message: resData?.message };
  } catch (e) {
    return {
      success: false,
      message: e.message || "Something went wrong",
    };
  }
};

export const loginWithFirebase = async (email, password) => {
  try {
    const firebaseUrl = import.meta.env.VITE_FIREBASE_LOGIN_URL;
    const firebaseKey = import.meta.env.VITE_FIREBASE_LOGIN_KEY;
    const res = await fetch(`${firebaseUrl}?key=${firebaseKey}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        returnSecureToken: true,
        email: email,
        password: password,
        clientType: "CLIENT_TYPE_WEB",
      }),
    });
    const data = await res.json();
    return {
      success: true,
      data,
    };
  } catch (e) {
    return {
      success: false,
      message: e.message || "Something went wrong",
    };
  }
};

export const extractCustomerDetails = (customerDetailsRes) => {
  const customerDetails = customerDetailsRes?.data?.data?.customerContact;
  const { givenName, familyName } = customerDetails?.givenAndFamilyName;
  const { telephoneNumber } = customerDetails?.telephoneContact[0];
  const { emailAddress } = customerDetails?.emailContact[0];
  const { accountNumber, usi } =
    customerDetails?.linkedAccountNumbers.length > 0
      ? customerDetails?.linkedAccountNumbers[0]
      : { accountNumber: "", usi: "" };
  return {
    email: emailAddress,
    firstName: givenName,
    lastName: familyName,
    phone: telephoneNumber,
    usi: usi,
  };
};

export const getCustomerDetails = async (email, token) => {
  try {
    const myAccountUrl = import.meta.env.VITE_MYACCOUNT_CUSTOMER_CONTACT_URL;
    const apiKey = import.meta.env.VITE_MYACCOUNT_API_KEY;
    const res = await fetch(myAccountUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        apikey: `${apiKey}`,
      },
      body: JSON.stringify({
        query: `
          query customerContact($email: String!) {
            customerContact(email: $email) {
              givenAndFamilyName {
                givenName
                familyName
              }
              telephoneContact {
                telephoneNumber
              }
              emailContact {
                emailAddress
              }
              linkedAccountNumbers {
                accountNumber
                usi
              }
            }
          }
          `,
        variables: {
          email: email,
        },
      }),
    });
    const data = await res.json();
    return {
      success: true,
      data,
    };
  } catch (e) {
    return {
      success: false,
      message: e.message || "Something went wrong",
    };
  }
};

export const saveCustomerSession = async (data, key) => {
  const payloadToBeEncripted = encrypt(data, key);
  try {
    const response = await fetch(
      `${import.meta.env.VITE_CUSTOMER_SESSION_API_URL}/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ payload: payloadToBeEncripted }),
      }
    );
    const resData = await response.json();
    return {
      success: true,
      data: resData,
    };
  } catch (e) {
    return {
      success: false,
      message: e.message || "Something went wrong",
    };
  }
};

export const getHashKey = async () => {
  let key = "";
  try {
    const fetchHashKey = await fetch(
      `${import.meta.env.VITE_CUSTOMER_SESSION_API_URL}/hashKey`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    const hash = await fetchHashKey.json();
    key = hash.response;
  } catch (e) {
    key = "";
    console.log(e);
  }
  return key;
};

export const generateFingerPrint = async () => {
  const fingerPrint = await getFingerprint();
  return fingerPrint;
};

export const encrypt = (content, password) =>
  AES.encrypt(JSON.stringify({ content }), password).toString();

export const decrypt = (crypted, password) =>
  JSON.parse(AES.decrypt(crypted, password).toString(enc.Utf8));
