<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";

import ChatEnd from "./components/ChatEnd.vue";
import ChatFooter from "./components/ChatFooter.vue";
import ChatHeader from "./components/ChatHeader.vue";
import AllMessages from "./components/AllMessages.vue";
import messsageSound from "./assets/message-received.mp3";
import ChatActivator from "./components/ChatActivator.vue";
import ConfirmEndChat from "./components/ConfirmEndChat.vue";
import PreChatScreens from "./components/PreChatScreens.vue";
import { ifExistingSession } from "./utils/existingSession";
import { onOffline, onOnline } from "./utils/internetDisconnectivity";
import { handleFileDownlaod, handleFileUpload } from "./utils/handleFiles";
import {
  typingEvent,
  onNewMessage,
  scrollToBottom,
  initializeAudio,
  sendUserMessage,
  clearSessionStore,
  addAgentAvailabilityToChannel,
} from "./utils/commonRules";
import {
  postChatExceptionsToKafkaTopic,
  postChatUserActionsToKafkaTopic,
} from "./utils/kafkaLogs";
import {
  EVENTS,
  CHAT_CHANNEL,
  REFRESH_INTERVAL,
  EXCEPTION_MESSAGE,
  INITIALIZATION_ERROR,
  OUTSIDE_OPERATING_HOURS,
} from "./constants";
import {
  clearSurvey,
  handleCleanup,
  handleEndChat,
  getOldChatMessages,
  handleStartNewChat,
  getConversationData,
  handleCancelEndChat,
  handleCloseChatWindow,
  requestEmailTranscript,
  checkAndSyncMessages,
  agentEndedSession,
} from "./utils/businessRules";

defineProps({
  isInternal: {
    type: Boolean,
    default: false,
  },
});

const omnichannelConfig = {
  orgUrl: import.meta.env.VITE_ORG_URL,
  orgId: import.meta.env.VITE_ORG_ID,
  widgetId: import.meta.env.VITE_ENTERPRISE_APP_ID,
};

const chatSDKConfig = {
  telemetry: {
    disable: true,
  },
};

const offset = ref(0);
const convId = ref("");
const chatId = ref(null);
const utc_now = ref(null);
const isTyping = ref(false);
const surveyUrl = ref(null);
const agentName = ref(null);
const allowChat = ref(true);
const isLoading = ref(true);
const userMuted = ref(false);
const chatMessages = ref([]);
const labelToIdMap = ref({});
const systemMessage = ref("");
const messageToSend = ref("");
const closedExtra = ref(false);
const emailTranscript = ref("");
const preChatQuestions = ref([]);
const channelOptions = ref(null);
const sendMessageError = ref("");
const offlineTimeout = ref(null);
const endChatLoading = ref(false);
const showChatWidget = ref(false);
const initializeError = ref(null);
const sendingMessage = ref(false);
const inactivityTimeout = ref("");
const transcriptSent = ref(false);
const waiting_to_start = ref(null);
const initiatingSurvey = ref(false);
const audioPlayEnabled = ref(false);
const confirmCloseChat = ref(false);
const transcriptSentFailed = ref("");
const transcriptSending = ref(false);
const areAgentsAvailable = ref(true);
const showPostChatSurvey = ref(false);
const showQualtricsSurvey = ref(false);
const confirmDecisionState = ref(false);
const isMobileApp = localStorage?.getItem("mobile");
const messageNotification = new Audio(messsageSound);
const url = import.meta.env.VITE_AGENT_AND_OOH_INFO_URL;
const chatSDK = new OmnichannelChatSDK(omnichannelConfig, chatSDKConfig);
const preChatSurveyResponses = ref({
  name: "",
  email: "",
  phone: "",
  companyName: "",
  subject: "",
});

const loadChannelOptions = async () => {
  const _url =
    url +
    "?" +
    new URLSearchParams({
      app_id: import.meta.env.VITE_ENTERPRISE_APP_ID,
    });
  fetch(_url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((channelConfig) => {
      utc_now.value = channelConfig.utcNow;
      channelOptions.value = addAgentAvailabilityToChannel(
        channelConfig.data,
        utc_now.value,
        offset.value
      );
    })
    .catch((e) => {
      console.log(e);
    });
};

const enterpriseChatInterval = setInterval(() => {
  offset.value = offset.value + REFRESH_INTERVAL;
  if (true) {
    channelOptions.value = addAgentAvailabilityToChannel(
      channelOptions?.value,
      utc_now.value,
      offset.value
    );
  }
}, REFRESH_INTERVAL);

onMounted(async () => {
  isLoading.value = true;
  await loadChannelOptions();
  await chatSDK.initialize();
  preChatQuestions.value = await chatSDK.getPreChatSurvey();
  isLoading.value = false;
  const sessionAlreadyExists = await ifExistingSession(
    CHAT_CHANNEL.ENTERPRISE,
    convId,
    endChat,
    chatSDK,
    isLoading,
    surveyUrl,
    closedExtra,
    chatMessages,
    showChatWidget,
    sendingMessage,
    channelOptions,
    emailTranscript,
    confirmCloseChat,
    showPostChatSurvey,
    initializeChatEvents,
    preChatSurveyResponses,
    null,
    null,
    null,
    false
  );
  if (sessionAlreadyExists) {
    return;
  }
});

onUnmounted(() => {
  clearInterval(enterpriseChatInterval);
});

const toggleChat = async () => {
  initializeAudio(false, audioPlayEnabled, userMuted);
  if (confirmCloseChat.value) return;
  showChatWidget.value = !showChatWidget.value;
  if (showChatWidget.value) {
    closedExtra.value = true;
    if (chatMessages.value.length > 0) {
      scrollToBottom();
    }
  }
};

const initializeChatEvents = async () => {
  const { messages } = await getOldChatMessages(
    chatSDK,
    preChatSurveyResponses.value,
    channelOptions.value,
    convId.value,
    CHAT_CHANNEL.ENTERPRISE
  );
  chatMessages.value = messages;
  await chatSDK.onNewMessage((message) => {
    onNewMessage(
      chatId,
      message,
      agentName,
      chatMessages,
      sendingMessage,
      showChatWidget,
      waiting_to_start,
      audioPlayEnabled,
      undefined,
      undefined,
      CHAT_CHANNEL.ENTERPRISE,
      null
    );
  });
  await chatSDK.onTypingEvent(() => {
    typingEvent(isTyping);
  });
  await chatSDK.onAgentEndSession(async () => {
    agentEndedSession(
      CHAT_CHANNEL.ENTERPRISE,
      convId,
      chatSDK,
      surveyUrl,
      chatMessages,
      sendingMessage,
      channelOptions,
      confirmCloseChat,
      showPostChatSurvey,
      preChatSurveyResponses
    );
    clearSessionStore(CHAT_CHANNEL.ENTERPRISE);
  });
};

const chatAction = (e) => {
  console.warn("CHAT INITIATED");
  toggleChat();
};

const startChat = async (preChatResponse, customContext) => {
  transcriptSent.value = false;
  try {
    initializeAudio(true, audioPlayEnabled, userMuted);
    isLoading.value = true;

    await chatSDK.startChat({
      preChatResponse: preChatResponse,
      customContext: customContext,
      sendDefaultInitContext: true,
    });
    console.warn("CHAT STARTED");
    const chatContext = await chatSDK.getCurrentLiveChatContext();
    sessionStorage.setItem(
      "MS365EnterpriseChatSession",
      JSON.stringify(chatContext)
    );
    sessionStorage.setItem(
      "MS365EnterpriseChatPreChatSurvey",
      JSON.stringify(preChatSurveyResponses.value)
    );
    await initializeChatEvents();
    checkAndSyncMessages(
      chatMessages,
      sendingMessage,
      waiting_to_start,
      CHAT_CHANNEL.ENTERPRISE,
      false
    );
    isLoading.value = false;
    initializeError.value = null;
    if (chatMessages.value.length > 0) {
      setTimeout(async () => {
        const { conversationDetails } = await getConversationData(
          chatSDK,
          chatMessages
        );
        convId.value = conversationDetails.conversationId;
        if (convId.value !== "") {
          try {
            await postChatUserActionsToKafkaTopic(
              EVENTS.START,
              CHAT_CHANNEL.ENTERPRISE,
              channelOptions.value,
              preChatSurveyResponses.value,
              convId.value,
              conversationDetails
            );
          } catch (e) {
            console.log(e);
          }
        }
      }, 10000);
    }
  } catch (e) {
    try {
      await postChatExceptionsToKafkaTopic(
        EVENTS.ERROR,
        CHAT_CHANNEL.ENTERPRISE,
        preChatSurveyResponses.value,
        channelOptions.value,
        e,
        convId.value,
        EXCEPTION_MESSAGE.INITIALIZE_CHAT
      );
    } catch (e) {
      console.log(e);
    }
    isLoading.value = false;
    if (e.message === "ConversationInitializationFailure") {
      await postChatExceptionsToKafkaTopic(
        EVENTS.ERROR,
        CHAT_CHANNEL.ENTERPRISE,
        preChatSurveyResponses.value,
        channelOptions.value,
        e,
        convId.value,
        EXCEPTION_MESSAGE.CONVERSATION_INITIALIZATION
      );
      initializeError.value = INITIALIZATION_ERROR;
    } else if (e.message === "WidgetUseOutsideOperatingHour") {
      await postChatExceptionsToKafkaTopic(
        EVENTS.ERROR,
        CHAT_CHANNEL.ENTERPRISE,
        preChatSurveyResponses.value,
        currentChannel.value,
        e,
        convId.value,
        EXCEPTION_MESSAGE.WIDGET_OUTSIDE_OPERATING_HOURS
      );
      initializeError.value = OUTSIDE_OPERATING_HOURS;
    }
    console.log(e);
  }
};

const sendMessage = async (message, metadata) => {
  sendUserMessage(
    chatSDK,
    message,
    metadata,
    userMuted,
    audioPlayEnabled,
    messageToSend,
    chatMessages,
    sendingMessage,
    convId,
    sendMessageError,
    preChatSurveyResponses,
    channelOptions,
    CHAT_CHANNEL.ENTERPRISE,
    false
  );
};

const customerTyping = async (event) => {
  if (event.key === "Enter" && messageToSend.value.length > 0) {
    await sendMessage();
  }
};

const endChat = async (confirmed = false, onInactive = false) => {
  handleEndChat(
    confirmed,
    onInactive,
    convId,
    cleanUp,
    chatSDK,
    surveyUrl,
    chatMessages,
    false,
    showChatWidget,
    emailTranscript,
    initiatingSurvey,
    confirmCloseChat,
    inactivityTimeout,
    showPostChatSurvey,
    confirmDecisionState,
    preChatSurveyResponses,
    CHAT_CHANNEL.ENTERPRISE
  );
};

const cancelEndChat = () => {
  handleCancelEndChat(
    endChat,
    showChatWidget,
    emailTranscript,
    confirmCloseChat,
    inactivityTimeout,
    showPostChatSurvey,
    confirmDecisionState,
    preChatSurveyResponses
  );
};

const cleanUp = () => {
  handleCleanup(
    chatId,
    agentName,
    surveyUrl,
    clearSurvey,
    closedExtra,
    chatMessages,
    labelToIdMap,
    messageToSend,
    false,
    offlineTimeout,
    sendingMessage,
    showChatWidget,
    emailTranscript,
    sendMessageError,
    confirmCloseChat,
    initiatingSurvey,
    inactivityTimeout,
    undefined,
    showPostChatSurvey,
    showQualtricsSurvey,
    confirmDecisionState,
    preChatSurveyResponses,
    CHAT_CHANNEL.ENTERPRISE,
    false
  );
};

const closeChatWindow = async (endChat = true) => {
  await handleCloseChatWindow(
    convId,
    chatSDK,
    undefined,
    cleanUp,
    startChat,
    showChatWidget,
    preChatQuestions,
    endChat,
    undefined,
    chatMessages,
    false,
    undefined,
    channelOptions,
    transcriptSent,
    endChatLoading,
    undefined,
    confirmCloseChat,
    clearSessionStore,
    showPostChatSurvey,
    showQualtricsSurvey,
    undefined,
    preChatSurveyResponses,
    CHAT_CHANNEL.ENTERPRISE
  );
};

const startNewChat = async (endChat = true) => {
  handleStartNewChat(
    cleanUp,
    undefined,
    startChat,
    toggleChat,
    undefined,
    undefined,
    undefined,
    preChatQuestions,
    undefined,
    false,
    showChatWidget,
    preChatSurveyResponses,
    CHAT_CHANNEL.ENTERPRISE,
    false
  );
};

const downloadFile = (fileMetadata) => {
  handleFileDownlaod(
    convId,
    chatSDK,
    fileMetadata,
    channelOptions,
    CHAT_CHANNEL.ENTERPRISE,
    preChatSurveyResponses
  );
};

const processUpload = async (evt) => {
  handleFileUpload(
    evt,
    convId,
    chatSDK,
    chatMessages,
    sendingMessage,
    sendMessageError,
    channelOptions,
    CHAT_CHANNEL.ENTERPRISE,
    preChatSurveyResponses
  );
};

const emailKeyUp = () => {
  transcriptSentFailed.value = "";
};

const handleRequestEmailTranscript = () => {
  requestEmailTranscript(
    convId,
    chatSDK,
    chatMessages,
    channelOptions,
    transcriptSent,
    emailTranscript,
    confirmCloseChat,
    transcriptSending,
    showPostChatSurvey,
    showQualtricsSurvey,
    transcriptSentFailed,
    CHAT_CHANNEL.ENTERPRISE,
    preChatSurveyResponses
  );
};

window.triggerEnterpriseOmniChat = (secretKey) => {
  if (secretKey === "D582223DCFE61") {
    showChatWidget.value = true;
  }
};

window.ononline = async () => {
  onOnline(
    convId,
    chatSDK,
    endChat,
    chatMessages,
    showChatWidget,
    sendingMessage,
    offlineTimeout,
    channelOptions,
    emailTranscript,
    sendMessageError,
    confirmCloseChat,
    inactivityTimeout,
    showPostChatSurvey,
    preChatSurveyResponses,
    CHAT_CHANNEL.ENTERPRISE
  );
};

window.onoffline = async () => {
  onOffline(sendingMessage, sendMessageError, offlineTimeout);
};
</script>

<template>
  <div
    id="chatWrapper"
    class="chatWrapper"
    v-if="areAgentsAvailable && allowChat"
  >
    <ChatActivator
      :is-loading="isLoading"
      :show-chat-widget="showChatWidget"
      :closedExtra="closedExtra"
      :chat-action="chatAction"
      :isEnterprise="false"
    />
    <div
      id="chatContainer"
      class="!zf-z-[1100] zf-fixed zf-bg-white zf-rounded zf-shadow zf-overflow-hidden !zf-w-12/12 !zf-h-[544px] sm:zf-w-[400px] !sm:zf-h-[546px] !zf-right-6 !zf-bottom-[100px] !sm:zf-right-16 !sm:zf-bottom-[126px] !zf-border !zf-border-white"
      v-if="showChatWidget"
    >
      <div
        id="mobHeader"
        class="!zf-h-[9%] !zf-bg-ziply-blue !zf-text-gray-100 !zf-flex !zf-justify-between !zf-w-full !zf-items-center !zf-px-4 !zf-rounded-t"
      >
        <ChatHeader
          :confirm-decision-state="confirmDecisionState"
          :toggle-chat="toggleChat"
          :confirm-close-chat="confirmCloseChat"
          :initiating-survey="initiatingSurvey"
          :end-chat="endChat"
          :show-qualtrics-survey="showQualtricsSurvey"
          :show-post-chat-survey="showPostChatSurvey"
          :close-chat-window="closeChatWindow"
          :isEnterprise="true"
        />
      </div>
      <div
        id="MobConvoBody"
        class="!zf-h-[91.2%] !zf-w-full !zf-text-[#3c3c3c] !zf-text-justify zf-overflow-hidden"
      >
        <div
          id="mobChatBody"
          v-if="chatMessages.length === 0"
          class="!zf-h-full zf-pt-2 zf-pb-2 zf-overflow-scroll"
        >
          <PreChatScreens
            :is-loading="isLoading"
            :initialize-error="initializeError"
            :channel-options="channelOptions"
            :start-chat="startChat"
            :pre-chat-survey-responses="preChatSurveyResponses"
            :pre-chat-questions="preChatQuestions"
            :is-internal="isInternal"
            :isEnterprise="true"
          />
        </div>
        <template v-else>
          <div
            class="!zf-flex zf-flex-col !zf-gap-2 !zf-h-full"
            v-if="!confirmCloseChat"
          >
            <div v-if="isMobileApp" id="mobBtnWrapper">
              <p id="mobCloseChatButton" @click="endChat()">END CHAT</p>
            </div>
            <AllMessages
              :chat-messages="chatMessages"
              :inputDisabledByAdaptiveCard="false"
              :send-message="sendMessage"
              :download-file="downloadFile"
              :pre-chat-survey-responses="preChatSurveyResponses"
            />
            <ChatFooter
              :is-typing="isTyping"
              :send-message-error="sendMessageError"
              :system-message="systemMessage"
              :message-to-send="messageToSend"
              @update:message-to-send="(newValue) => (messageToSend = newValue)"
              :process-upload="processUpload"
              :sending-message="sendingMessage"
              :send-message="sendMessage"
              :customer-typing="customerTyping"
            />
          </div>
          <div
            class="!zf-w-full !zf-h-full !zf-bg-white"
            v-if="confirmCloseChat"
          >
            <div
              v-if="!showPostChatSurvey"
              class="zf-text-center zf-flex zf-flex-col zf-items-center zf-justify-center zf-h-full zf-gap-4 zf-px-6"
            >
              <ConfirmEndChat
                :end-chat-loading="endChatLoading"
                :inactivity-timeout="inactivityTimeout"
                :request-email-transcript="handleRequestEmailTranscript"
                :email-transcript="emailTranscript"
                @update:email-transcript="
                  (newValue) => (emailTranscript = newValue)
                "
                :email-key-up="emailKeyUp"
                :transcript-sent-failed="transcriptSentFailed"
                :transcript-sending="transcriptSending"
                :transcript-sent="transcriptSent"
                @closeChatWindow="closeChatWindow"
                @cancelEndChat="cancelEndChat"
              />
            </div>
            <template v-else>
              <ChatEnd
                :email-transcript="emailTranscript"
                :show-qualtrics-survey="showQualtricsSurvey"
                @update:show-qualtrics-survey="
                  (newValue) => (showQualtricsSurvey = newValue)
                "
                :transcript-sent="transcriptSent"
                :start-new-chat="startNewChat"
                :survey-url="surveyUrl"
                :is-internal="false"
                :sales-agents-available="true"
                :channel-options="channelOptions"
                :pre-chat-survey-responses="preChatSurveyResponses"
                :conv-id="convId"
              />
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
